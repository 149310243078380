/*
 * @Description:
 * @Author: Summer.Chen
 * @Date: 2024-01-26 15:12:03
 * @LastEditTime: 2024/12/27 10:35:16
 * @LastEditors: Please set LastEditors
 */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import '@/style/styles/index.less';
import '@/style/styles/maintable.less';
import App from './HotApp';
import * as serviceWorker from './serviceWorker';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './i18n/i18n';
import { message, ConfigProvider } from 'antd';

message.config({
  maxCount: 1
});
message.destroy();
// import "lib-flexible";
ReactDOM.render(
  // <React.StrictMode>
  <ConfigProvider
    getPopupContainer={node => {
      if (node) {
        return node.parentNode;
      }
      return document.body;
    }}
  >
    <App />
  </ConfigProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.unregister();
